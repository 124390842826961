.headerContainer {
  position: absolute;
  width: 100%;
  z-index: 900;
  top: 0;
  left: 0;
  height: 150px;
}

.headerContainer nav {
  height: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
}

.headerListItems {
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 500ms;
}

.headerItem {
  font-size: 1.2em;
  font-weight: 500;
}

.headerItem a {
  transition: color 350ms;
  padding: 1rem;
  display: block;
}

.headerItem a:hover {
  color: var(--primary-green);
}

.headerMobileNav {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: none;
}

.headerLogo,
.headerLogoMobile {
  height: 100%;
}

.headerLogo a,
.headerLogoMobile a {
  height: 100%;
  display: block;
}

.headerLogo img,
.headerLogoMobile img {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  width: 100px;
  min-width: 100%;
  max-width: 100%;
  object-fit: contain;
}

.menuIcon {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}
.menuLine {
  fill: none;
  stroke: var(--primary-green);
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.menuLine1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.menuLine2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.menuLine3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.menuOpened .menuLine1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.menuOpened .menuLine2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.menuOpened .menuLine3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

/* a.buyNowBtn {
  opacity: 0;
  cursor: default;
  background: var(--primary-purple);
  border-radius: 50px;
  padding: 0.6rem 2rem;
  transition: background 350ms;
}

a.buyNowBtn:hover {
  color: white;
  background: var(--primary-pink);
} */

.headerItem .buyNowBtn {
  /* opacity: 0;
  cursor: default; */
  background: var(--primary-purple);
  border-radius: 50px;
  padding: 0.6rem 2rem;
  transition: background 350ms;
}

.headerItem .buyNowBtn:hover {
  color: white;
  background: var(--primary-pink);
}

@media (max-width: 992px) {
  .headerContainer {
    background: linear-gradient(to right, var(--secondary-purple) 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 500ms;
  }
  .headerListItems {
    flex-direction: column;
  }
  .headerMobileNav {
    display: flex;
  }
  .headerLogo {
    display: none;
  }
  .headerListItems {
    height: 100vh;
    background: var(--secondary-purple);
    position: absolute;
    justify-content: flex-start;
    align-items: center;
    margin: auto;
    width: inherit;
    margin-top: 2rem;
    left: -100vw;
  }
  .headerOpen.headerContainer {
    background-position: left bottom;
  }
  .headerOpen .headerListItems {
    left: 0;
  }
}
