.faqSection {
  margin-top: 10rem;
  position: relative;
  z-index: 2;
}

.faqSection__title {
  color: var(--primary-green);
  width: min-content;
  margin: auto;
  font-family: "azonix";
  margin-bottom: 3rem;
}

.faqAccordion {
  margin-top: 2rem;
}

.accordionItem {
  width: 100%;
  cursor: pointer;
  border: 1px solid var(--primary-purple);
  border-radius: 4px;
  padding: 1rem 1.5rem;
  margin: 1rem 0;
  transition: background 350ms;
}

.accordionItem__title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--primary-purple);
  font-size: 1.4em;
  font-weight: 700;
}

.accordionItem__title span {
  display: flex;
  align-items: center;
}

.accordionItem__title svg {
  box-sizing: border-box;
  height: 15px;
  transform: rotate(180deg);
  fill: var(--primary-purple);
  transition: fill 350ms, transform 350ms;
}

.accordionItem__text {
  /* height: 0; */
  overflow: hidden;
  font-size: 1.1em;
  white-space: pre-wrap;
  margin-top: 0;
  letter-spacing: 0.5px;
  line-height: 28px;
  transition: all 350ms;
}

.accordionItem:hover {
  background: var(--primary-purple);
}

.accordionItem:hover .accordionItem__title {
  color: var(--secondary-purple);
}

.accordionItem:hover .accordionItem__title svg {
  fill: var(--secondary-purple);
}

.accordionItem.accordionOpen {
  background: var(--primary-purple);
}

.accordionOpen .accordionItem__title {
  color: var(--secondary-purple);
}

.accordionOpen .accordionItem__title svg {
  transform: rotate(0deg);
  fill: var(--secondary-purple);
}

.accordionOpen .accordionItem__text {
  margin-top: 0.5rem;
}

@media (max-width: 992px) {
  .faqSection {
    margin-top: 5rem;
  }
}

@media (max-width: 576px) {
  .accordionItem__title {
    font-size: 1.2em;
  }

  .accordionItem__title svg {
    height: 13px;
  }
}
