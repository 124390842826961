@font-face {
  font-family: "azonix";
  src: url("/public/assets/fonts/azonix/Azonix.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins";
  src: url("/public/assets/fonts/Poppins/Poppins-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "poppins";
  src: url("/public/assets/fonts/Poppins/Poppins-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "poppins";
  src: url("/public/assets/fonts/Poppins/Poppins-ExtraLight.ttf");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "poppins";
  src: url("/public/assets/fonts/Poppins/Poppins-Thin.ttf");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "poppins";
  src: url("/public/assets/fonts/Poppins/Poppins-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "poppins";
  src: url("/public/assets/fonts/Poppins/Poppins-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "poppins";
  src: url("/public/assets/fonts/Poppins/Poppins-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "poppins";
  src: url("/public/assets/fonts/Poppins/Poppins-ExtraBold.ttf");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "poppins";
  src: url("/public/assets/fonts/Poppins/Poppins-Black.ttf");
  font-weight: 900;
  font-style: normal;
}

/* * ****************************** GLOBALS */
:root {
  --primary-purple: #5b42ff;
  --secondary-purple: #0c1137;

  --primary-pink: #57226e;

  --primary-green: #8ad307;

  --text-color: #fff;
}

html,
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--secondary-purple);
  color: var(--text-color);
  font-family: "poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}
body {
  overflow-x: hidden;
}

body.noscroll {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
h2 {
  font-size: 2.5em;
}

@media (max-width: 375px) {
  body {
    font-size: 14px;
  }
}

/* * ****************************** BOOTSTRAP CONTAINER */

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    width: 1170px;
  }
}
@media (min-width: 1400px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    width: 1320px;
  }
}

@media (max-width: 576px) {
  :root {
    --one-sided-container: 0.75rem;
  }
}

@media (min-width: 576px) {
  :root {
    --one-sided-container: calc((100vw - 540px) / 2);
  }
}

@media (min-width: 768px) {
  :root {
    --one-sided-container: calc(((100vw - 720px) / 2) + 12px);
  }
}
@media (min-width: 992px) {
  :root {
    --one-sided-container: calc(((100vw - 960px) / 2) + 12px);
  }
}
@media (min-width: 1200px) {
  :root {
    --one-sided-container: calc(((100vw - 1140px) / 2) + 12px);
  }
}
@media (min-width: 1400px) {
  :root {
    --one-sided-container: calc(((100vw - 1320px) / 2) + 12px);
  }
}
