.storyContainer {
  position: relative;
  padding-top: 140px;
  padding-bottom: 50px;
}

/* * ************************* */
.contentContainer {
  width: 100%;
  background: linear-gradient(90deg, var(--primary-pink), var(--primary-pink), var(--primary-purple), var(--primary-purple), var(--primary-purple));
  display: grid;
  grid-template-columns: 0.9fr 1fr;
}
/* * ******** */
.contentImageContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.contentImageWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  height: calc(100% + 120px);
  width: 100%;
}

.contentImageWrapper > img {
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  object-position: bottom;
}

/* * ******** */
.contentTextContainer {
  padding: 4rem 3rem 4rem 0;
}

.contentTitle {
  text-transform: uppercase;
  font-family: "azonix";
  font-size: 2.5em;
  margin: 0 0 10px 0;
  font-weight: 400;
}

.contentTextBold {
  font-size: 1.05em;
  font-weight: 700;
  margin: 0 0 5px 0;
}
.contentText {
  font-size: 1em;
  margin: 0;
}

/* * ************************* */
.logoContainer {
  position: absolute;
  bottom: 0;
  left: 11.5%;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 15px;
  background-color: var(--secondary-purple);
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 1400px) {
  .logoContainer {
    left: 9.5%;
  }
  .contentTitle {
    font-size: 3em;
  }

  .contentTextBold {
    font-size: 1.25em;
  }
  .contentText {
    font-size: 1.2em;
  }
}

@keyframes rotate-logo {
  0% {
    transform: none;
  }
  100% {
    transform: rotate(360deg);
  }
}

.logoWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.logoWrapper > img {
  width: 100%;
  height: 100%;
  animation: rotate-logo 10s infinite linear;
}

@media (max-width: 992px) {
  .storyContainer {
    padding-top: 60px;
  }
  .contentContainer {
    grid-template-columns: 1fr;
    background: linear-gradient(0deg, var(--primary-pink), var(--primary-purple));
  }
  .contentTextContainer {
    padding: 2rem 1.5rem;
  }
  .contentImageWrapper {
    display: none;
  }
  .logoContainer {
    right: 11.5%;
    left: unset;
    height: 80px;
    width: 80px;
  }
}
