.footer {
  margin-top: 13rem;
  /* background-image: url("/public/assets/images/hero-background-img.png"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.backgroundImage img {
  min-width: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
  overflow: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-65%);
  z-index: 1;
}

.footerContent {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.footerLogo {
  width: 170px;
}

.footerLogo img {
  height: 100%;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  max-width: 100%;
  object-fit: contain;
  margin-bottom: 2rem;
}

.socials {
  display: flex;
  margin-bottom: 2rem;
}

.socials a {
  margin: 0 0.75rem;
  width: 45px;
}

.socials img {
  height: 100%;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  max-width: 100%;
  object-fit: contain;
}

.buyLink {
  margin-bottom: 0.7rem;
}

.buyLink a {
  color: var(--primary-green);
  text-decoration: underline;
  transition: color 250ms;
}

.buyLink a:hover {
  color: var(--primary-green);
}

.copyrightText {
  font-size: 1.3em;
}

.icon3d:hover {
  animation: icon3d 200ms infinite;
  transition: all 400ms;
}
@keyframes icon3d {
  0% {
    filter: drop-shadow(5px 4px var(--primary-pink)) drop-shadow(-5px -6px var(--primary-purple));
  }
  25% {
    filter: drop-shadow(-5px -6px var(--primary-pink)) drop-shadow(5px 4px var(--primary-purple));
  }
  50% {
    filter: drop-shadow(5px -4px var(--primary-pink)) drop-shadow(-8px 4px var(--primary-purple));
  }
  75% {
    filter: drop-shadow(-8px -4px var(--primary-pink)) drop-shadow(-5px -4px var(--primary-purple));
  }
  100% {
    filter: drop-shadow(-5px -0px var(--primary-pink)) drop-shadow(5px -4px var(--primary-purple));
  }
}

@media (max-width: 1200px) {
  .footer {
    margin-top: 8rem;
  }
  .footerLogo {
    width: 150px;
  }
  .backgroundImage img {
    height: 750px;
  }
}

@media (max-width: 768px) {
  .footer {
    padding-bottom: 5rem;
  }
  .footerLogo {
    width: 130px;
  }
  .backgroundImage img {
    height: 800px;
  }
  .socials a {
    width: 40px;
  }
}
