.homeHeroSection {
  height: 100vh;
  background-image: url("/public/assets/images/the-bhive-hero-background-img.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  overflow: hidden;
}

.heroContentWrapper {
  position: absolute;
  bottom: 4rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.heroIntro__characters {
  position: absolute;
  bottom: 3rem;
  height: 70vh;
  display: flex;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  justify-content: center;
}

.character__img {
  height: 100%;
}

.heroIntro__characters > .character__img:nth-child(1) {
  position: relative;
  left: 10px;
  z-index: 2;
}

.heroIntro__characters > .character__img:nth-child(2) {
  position: relative;
  left: -50px;
  z-index: 1;
}

.heroIntro__characters > .character__img:nth-child(3) {
  position: relative;
  right: -50px;
  z-index: 1;
}

.heroIntro__characters > .character__img:nth-child(4) {
  position: relative;
  right: 10px;
  z-index: 2;
}

.character__img img {
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.heroTextWrapper {
  text-transform: uppercase;
  font-weight: 500;
  font-family: "azonix";
  width: max-content;
  margin: auto;
  text-align: center;
}

.heroText__intro {
  font-size: 2.5em;
  margin-bottom: 5px;
}

.heroText__title {
  color: var(--primary-green);
  width: 100%;
  font-size: 4.2em;
}

.heroText__arrow {
  height: 25px;
  width: 44px;
  margin: auto;
  margin-top: 1.3rem;
}

.heroText__arrow img {
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  max-height: 100%;
  max-width: 100%;
  cursor: pointer;
  animation: bounceUpDown 0.7s linear infinite;
  animation-direction: alternate-reverse;
}

@keyframes bounceUpDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(10px);
  }
}

.heroBottomGradient {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1;
  height: 400px;
  background: linear-gradient(to top, var(--secondary-purple) 30%, transparent);
}

.heroBottomGradient img {
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  max-height: 100%;
  max-width: 100%;
}

@media (max-width: 1500px) {
  .heroText__intro {
    font-size: 1.8em;
  }

  .heroText__title {
    font-size: 3em;
  }
}

@media (max-width: 992px) {
  .heroTextWrapper {
    width: max-content;
  }

  .heroText__intro {
    font-size: 1.4em;
  }

  .heroText__title {
    font-size: 2.9em;
  }
}

@media (max-width: 768px) {
  .homeHeroSection {
    /* height: 85vh; */
    height: 100vh;
  }
  .heroIntro__characters {
    height: 450px;
  }
  .heroText__intro {
    font-size: 1em;
  }
  .heroText__title {
    font-size: 2.1em;
  }
  .heroIntro__characters > .character__img:nth-child(2) {
    left: -30px;
  }

  .heroIntro__characters > .character__img:nth-child(3) {
    right: -30px;
  }
}
@media (max-width: 576px) {
  .heroText__intro {
    font-size: 1.4em;
  }
  .heroText__title {
    font-size: 2em;
  }
  /* .heroIntro__characters {
    height: 450px;
    gap: 2rem;
  } */

  .heroIntro__characters > .character__img:nth-child(1) {
    position: absolute;
    left: -105px;
    top: -90px;
    z-index: 1;
    width: 300px;
  }
  .heroIntro__characters > .character__img:nth-child(2) {
    left: 0;
    z-index: 2;
  }
  .heroIntro__characters > .character__img:nth-child(3) {
    right: 0;
    z-index: 2;
  }

  .heroIntro__characters > .character__img:nth-child(4) {
    position: absolute;
    right: -105px;
    top: -90px;
    z-index: 1;
    width: 300px;
  }
}

@media (max-width: 330px) {
  .heroText__intro {
    font-size: 1.2em;
  }
  .heroText__title {
    font-size: 1.7em;
  }
}
