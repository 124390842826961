.roadmapContainer {
  padding-top: 40px;
}
/* ***************************** */
.sectionHeadContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 4rem;
}

.headElement {
  position: relative;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 500ms;
}

.headElement.active {
  opacity: 1;
}

.headElement::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 12px;
  background-color: var(--primary-purple);
}

.headPreTitle {
  font-size: 1.3em;
  font-family: "azonix";
  color: var(--primary-green);
}

.headTitle {
  font-size: 2.5em;
  font-weight: 700;
  margin-top: -0.3em;
}

.topLine {
  margin: 0;
  border-color: var(--primary-pink);
}

/* ***************************** */

.timeLineContainer {
  padding-top: 3rem;
}

/* .seasonTwo {
  font-size: 1.35em;
  font-weight: 500;
  text-align: center;
} */

@media (max-width: 576px) {
  .sectionHeadContainer {
    padding: 0;
  }

  .headPreTitle {
    font-size: 1.15em;
  }

  .headTitle {
    font-size: 2em;
  }
}

@media (max-width: 768px) {
}
@media (max-width: 992px) {
}
@media (max-width: 1200px) {
}
@media (max-width: 1400px) {
}
