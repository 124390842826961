.noColor {
  color: transparent;
}

.sectionContainer {
  padding-top: 7rem;
}

.sectionTitle {
  font-family: "azonix";
  color: var(--primary-green);
  text-align: center;
  margin: 0 0 2rem 0;
}

.sectionSubtitle {
  font-size: 1.25em;
  text-align: center;
  margin: 0 0 2rem 0;
  font-weight: 500;
}

/* * ********************* */
.sliderContainer {
  padding-left: var(--one-sided-container);
  width: 100%;
}

.slideWrapper {
  height: 504px;
  width: 410px !important;
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
/* * ********************* */
.slideBackgroundContainer {
  position: absolute;
  width: 100%;
  height: calc(100% - 30px);
  top: 0;
  left: 0;
  z-index: 1;
}

.slideBackgroundWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.slideBackgroundWrapper > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
/* * ********************* */
.slideContentContainer {
  position: absolute;
  width: 100%;
  height: calc(100% - 20px);
  top: 0;
  left: 0;
  z-index: 2;
}

.slideContentWrapper {
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 20px 20px 30px 20px;
  background: linear-gradient(180deg, #000, transparent);
}

.slideTitle {
  font-size: 1.35em;
  font-weight: 600;
  margin: 0;
}

.slideSubtitle {
  font-size: 1.2em;
  font-weight: 400;
  margin: 0;
  color: var(--primary-green);
}

/* * ********************* */
.slideSocialContainer {
  position: absolute;
  bottom: 0px;
  left: 30px;
  z-index: 3;
  background-color: var(--primary-purple);
  border-radius: 7px;
  box-shadow: 0 -0.5rem 2rem 0.5rem rgba(0, 0, 0, 0.7);
  transition: all 500ms;
}

.slideSocialWrapper {
  width: 60px;
  height: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slideSocialContainer:hover {
  box-shadow: none;
  background-color: var(--primary-green);
}
.slideSocialContainer svg {
  width: 60%;
  height: 60%;
}
.slideSocialContainer svg path {
  transition: all 500ms;
}
.slideSocialContainer:hover svg path {
  fill: var(--secondary-purple);
}

/* * ********************* */
.arrowsContainer {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.arrow {
  width: 75px;
  height: 25px;
  background-color: transparent;
  background-image: url("/public/assets/images/the-bhive-slider-arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
}
.arrow.next {
  transform: rotateY(180deg);
}

@media (max-width: 1400px) {
  .slideWrapper {
    height: 400px;
    width: 310px !important;
  }
}

@media (max-width: 992px) {
  .arrowsContainer {
    display: none;
  }
}

/* * ****************************************** */
/* * ****************************************** */
.c_glitch,
.c_glitch__img {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.c_glitch {
  width: 100%;
  position: relative;
  height: 100%;
}

.c_glitch__img {
  background-blend-mode: none;
  background-color: transparent;
  height: calc(100% + 5px * 2);
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translate3d(0, 0, 0);
  width: calc(100% + 10px * 2);
}

.slideContentContainer:hover + .slideBackgroundContainer .c_glitch__img:nth-child(n + 2) {
  animation-duration: 2s;
  animation-delay: 0;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

.slideContentContainer:hover + .slideBackgroundContainer .c_glitch__img:nth-child(2) {
  animation-name: glitch-anim-1;
}

.slideContentContainer:hover + .slideBackgroundContainer .c_glitch__img:nth-child(3) {
  animation-name: glitch-anim-2;
}

.slideContentContainer:hover + .slideBackgroundContainer .c_glitch__img:nth-child(4) {
  animation-name: glitch-anim-3;
}

.slideContentContainer:hover + .slideBackgroundContainer .c_glitch__img:nth-child(5) {
  animation-name: glitch-anim-4;
  background-blend-mode: overlay;
  background-color: #af4949;
}

.c-glitch__img:nth-child(n + 2) {
  opacity: 0;
}

/* * ************************************************************ */
/* * ************************************************************ */
/* * ************************************************************ */

@keyframes glitch-anim-1 {
  0%,
  100% {
    opacity: 1;
    transform: translate3d(40px, 0, 0) scale3d(-1, -1, 1);
    clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
  }

  20% {
    clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
  }

  30% {
    clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
  }

  40% {
    clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
  }

  50% {
    clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
  }

  55% {
    clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
  }

  60% {
    clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
  }

  65% {
    clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
  }

  70% {
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
  }

  80% {
    clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
  }

  85% {
    clip-path: polygon(0 60%, 100% 60%, 100% 65%, 0 65%);
  }

  95% {
    clip-path: polygon(0 72%, 100% 72%, 100% 78%, 0 78%);
  }
}

@keyframes glitch-anim-2 {
  0%,
  100% {
    opacity: 1;
    transform: translate3d(-10px, 0, 0);
    clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
  }

  10% {
    clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
  }

  15% {
    clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
  }

  17% {
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }

  19% {
    clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
  }

  33% {
    clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
  }

  35% {
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }

  40% {
    clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
  }

  45% {
    clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
  }

  49% {
    clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
  }

  50% {
    clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
  }

  55% {
    clip-path: polygon(0 15%, 100% 15%, 100% 35%, 0 35%);
  }

  60% {
    clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
  }

  70% {
    clip-path: polygon(0 65%, 100% 65%, 100% 60%, 0 60%);
  }

  80% {
    clip-path: polygon(0 80%, 100% 80%, 100% 85%, 0 85%);
  }

  90% {
    clip-path: polygon(0 55%, 100% 55%, 100% 65%, 0 65%);
  }
}

@keyframes glitch-anim-3 {
  0%,
  100% {
    opacity: 1;
    transform: translate3d(0, -5px, 0) scale3d(-1, -1, 1);
    clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
  }

  5% {
    clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
  }

  11% {
    clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
  }

  20% {
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }

  25% {
    clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
  }

  35% {
    clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
  }

  42% {
    clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
  }

  48% {
    clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
  }

  50% {
    clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
  }

  56% {
    clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
  }

  61% {
    clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
  }

  68% {
    clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
  }

  72% {
    clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
  }

  77% {
    clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
  }

  81% {
    clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
  }

  86% {
    clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
  }

  90% {
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }

  92% {
    clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
  }

  94% {
    clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
  }
}

@keyframes glitch-anim-4 {
  0%,
  5% {
    opacity: 0.2;
    transform: translate3d(10px, 5px, 0);
  }

  5.5%,
  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
}
