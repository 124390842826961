.timelineContainer {
  padding-left: 4rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

/* * ********** items */
.timelineItem {
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 75px;
  position: relative;
  color: #fff;
  transition: color 600ms;
}

.timelineItem.active {
  color: var(--primary-green);
}

.timelineItem::before {
  content: "⬢";
  color: #fff;
  width: 15px;
  height: 15px;
  position: absolute;
  z-index: 2;
  left: -3rem;
  top: 3px;
  border-radius: 50%;
  transition: all 600ms;
}

.timelineItem.active::before {
  color: var(--primary-green);
}

.timelineItem::after {
  content: "";
  width: 1px;
  height: calc(100% + 55px);
  position: absolute;
  z-index: 1;
  left: -42.5px;
  top: 25px;
  border-left: 3px dotted #fff;
  transition: all 600ms;
}

.timelineItem.activeLine::after {
  border-left: 3px dotted var(--primary-green);
}

.timelineWrapper > div:last-of-type::after {
  content: unset;
}

.timelineTitle {
  font-size: 1.3em;
  font-weight: 700;
}

.timelineText {
  font-size: 1.1em;
  font-weight: 400;
  white-space: pre-line;
}

@media (max-width: 576px) {
  .timelineContainer {
    padding-left: 3rem;
  }

  .timelineItem::before {
    left: -2rem;
  }

  .timelineItem::after {
    left: -26px;
  }

  .timelineTitle {
    font-size: 1.1em;
  }

  .timelineText {
    font-size: 1em;
  }
}
