.aboutContainer {
  padding: 4rem 6rem 0 6rem;
}

.aboutText {
  text-align: center;
  font-size: 1.25em;
  line-height: 1.35em;
}

@media (max-width: 992px) {
  .aboutContainer {
    padding: 4rem 3rem 0 3rem;
  }
}

@media (max-width: 768px) {
  .aboutContainer {
    padding: 1rem 0 0 0;
  }
}
