.goToTop {
  height: 55px;
  width: 55px;
  background: var(--primary-pink);
  fill: #fff;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 9;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 600ms, background 400ms, fill 400ms;
}

.goToTop:hover {
  background: var(--primary-green);
  fill: var(--secondary-purple);
}

.goToTop svg {
  height: 42%;
  width: 42%;
}
